import React, { useEffect, useState } from 'react'
import { gapi } from 'gapi-script'
import { CustomThemeButton } from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'
import Spinner from '@atlaskit/spinner'
import Textfield from '@atlaskit/textfield'

import InfoIcon from '@atlaskit/icon/glyph/info'
import LinkIcon from '@atlaskit/icon/glyph/link-filled'
import LockIcon from '@atlaskit/icon/glyph/lock-circle'
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'

import * as Helpers from './helpers'

import './App.css'

const CLIENT_ID =
    '466668621434-uq22ihqh8vdl2tqpvjo9kqsarkqc617u.apps.googleusercontent.com'
const SCOPES = 'https://www.googleapis.com/auth/drive.readonly'

const App = () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const [fileId, setFileId] = useState('')
    const [hasSource, setHasSource] = useState(true)
    const [pastedURL, setPastedURL] = useState('')

    const [showLogin, setShowLogin] = useState(false)
    const [authToken, setAuthToken] = useState(null)

    useEffect(() => {
        const src = new URLSearchParams(window.location.search).get('src')
        if (!src) {
            setHasSource(false)
            setLoading(false)
            return
        }

        switch (src.charAt(0)) {
            case 'G': // private GDrive
                setFileId(src)
                gapi.load('client:auth2', initGoogle)
                if (authToken) diagramGoogle(src.substring(1), authToken)
                break
            case 'R': // url encoded
                diagramEncoded(src.substring(1))
                break
            case 'U': // public link
                diagramURL(src.substring(1))
                break
            case 'V': // VSDX link
                diagramVisio(src.substring(1))
                break
            default:
                diagramEncoded(src.substring(1))
                break
        }
    }, [authToken])

    const initGoogle = () => {
        gapi.client
            .init({
                clientId: CLIENT_ID,
                scope: SCOPES,
            })
            .then(() => {
                gapi.auth2.getAuthInstance().isSignedIn.listen(updateGoogle)
                updateGoogle(gapi.auth2.getAuthInstance().isSignedIn.get())
            })
    }

    const updateGoogle = (isSignedIn) => {
        if (isSignedIn) {
            const currentAuth = gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getAuthResponse().access_token
            setAuthToken(currentAuth)
            setShowLogin(false)
        } else {
            setShowLogin(true)
            setLoading(false)
        }
    }

    const loginGoogle = () => {
        const GoogleAuth = gapi.auth2.getAuthInstance()
        GoogleAuth.signIn().then(() =>
            updateGoogle(GoogleAuth.isSignedIn.get())
        )
    }

    const diagramGoogle = (src, token = null) =>
        fetch(
            `https://www.googleapis.com/drive/v3/files/${src}?alt=media&source=downloadUrl`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((res) => {
                if (res.status === 404) throw new Error('account') // wrong account usually
                return res.text()
            })
            .then((data) => renderDiagram(data))
            .catch((err) => {
                const accErr = err.message === 'account'
                setError(
                    accErr
                        ? 'Try another Google account'
                        : 'Error fetching data'
                )
                if (accErr) setShowLogin(true)
                setLoading(false)
            })

    const diagramEncoded = (src) => {
        const data = !src.startsWith('<') ? Helpers.decodeXML(src) : src
        renderDiagram(data)
    }

    const diagramURL = (src) =>
        fetch(`https://api.appsplus.co/proxy/${src}`)
            .then((res) => {
                if (res.status === 404) throw new Error()
                return res.text()
            })
            .then((data) => renderDiagram(data))
            .catch(() => {
                setError('Error fetching data')
                setLoading(false)
            })

    const diagramVisio = (src) => {
        if (!src.includes('.vsdx')) {
            setError('VSDX files only')
            setLoading(false)
            return
        }

        fetch(
            !src.includes('appsplus.co')
                ? `https://api.appsplus.co/proxy/${src}`
                : src
        )
            .then((res) => {
                if (res.status === 404) throw new Error()
                return res.blob()
            })
            .then((data) => {
                const editorUi = new window.HeadlessEditorUi()
                editorUi.importVisio(
                    data,
                    (xml) => renderDiagram(xml),
                    () => {
                        setError('Error fetching data')
                        setLoading(false)
                    }
                )
            })
            .catch(() => {
                setError('Error fetching data')
                setLoading(false)
            })
    }

    const followSource = () => {
        const url = new URL(pastedURL)
        const hash = pastedURL.includes('drive.google.com')
            ? 'G' +
              pastedURL.match(
                  /(?:https?:\/\/)?(?:www\.)?drive\.google\.com\/.*?\/d\/(.+?)(?:\/|$|&|\?)/
              )?.[1]
            : url.hash.substring(1)
        const redirectURL = `https://diagrams.appsplus.co/?src=${hash}`
        window.location.href = redirectURL
    }

    const renderDiagram = (data) => {
        if (!data?.includes('<mx')) {
            setError('Invalid diagram data')
            setLoading(false)
            return
        }

        const config = { highlight: '#3572b0', nav: true, lightbox: false }
        const viewer = new window.GraphViewer(null, null, config)

        viewer.xml = data
        viewer.lightboxChrome = false
        viewer.layersEnabled = true

        const ui = viewer.showLocalLightbox()
        ui.chromelessToolbar.lastChild.remove()

        setLoading(false)
    }

    return (
        <div className="wrapper">
            {loading && <Spinner />}
            {!hasSource && !loading && (
                <>
                    <div className="landing flex-col">
                        <h3>
                            Paste your{' '}
                            <a
                                href="https://app.diagrams.net"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                            >
                                diagrams.net
                            </a>{' '}
                            link below
                        </h3>
                        <div className="form">
                            <Textfield
                                placeholder="https://viewer.diagrams.net/[...]"
                                onChange={(e) => setPastedURL(e.target.value)}
                            />
                            <CustomThemeButton
                                appearance="primary"
                                onClick={followSource}
                                theme={(current, props) => {
                                    const { buttonStyles, rest } =
                                        current(props)
                                    return {
                                        buttonStyles: {
                                            ...buttonStyles,
                                            alignItems: 'center',
                                            height: 54,
                                            width: 100,
                                            fontSize: 16,
                                        },
                                        ...rest,
                                    }
                                }}
                            >
                                View
                            </CustomThemeButton>
                        </div>
                    </div>
                    <div className="boxes">
                        <div className="box">
                            <InfoIcon
                                size="large"
                                primaryColor="#F4F5F7"
                                secondaryColor="#172B4D"
                            />
                            <span>View fullscreen interactive diagrams</span>
                        </div>
                        <div className="box">
                            <LinkIcon
                                size="large"
                                primaryColor="#F4F5F7"
                                secondaryColor="#172B4D"
                            />
                            <span>Link to public or private diagrams</span>
                        </div>
                        <div className="box">
                            <LockIcon
                                size="large"
                                primaryColor="#F4F5F7"
                                secondaryColor="#172B4D"
                            />
                            <span>Only XML diagram data is accessed</span>
                        </div>
                    </div>
                </>
            )}
            {showLogin && !loading && (
                <div className="login flex-col">
                    <button
                        className="gsi-material-button"
                        onClick={loginGoogle}
                    >
                        <div className="gsi-material-button-state"></div>
                        <div className="gsi-material-button-content-wrapper">
                            <div className="gsi-material-button-icon">
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                    style={{ display: 'block' }}
                                >
                                    <path
                                        fill="#EA4335"
                                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                    ></path>
                                    <path
                                        fill="#4285F4"
                                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                    ></path>
                                    <path
                                        fill="#FBBC05"
                                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                    ></path>
                                    <path
                                        fill="#34A853"
                                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                    ></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                </svg>
                            </div>
                            <span className="gsi-material-button-contents">
                                Sign in with Google
                            </span>
                        </div>
                    </button>
                    <span className="separator">OR</span>
                    <CustomThemeButton
                        appearance="default"
                        iconBefore={<ShortcutIcon size="small" />}
                        href={`https://app.diagrams.net/#${fileId}`}
                        target="blank"
                        theme={(current, props) => {
                            const { buttonStyles, rest } = current(props)
                            return {
                                buttonStyles: {
                                    ...buttonStyles,
                                    width: 180,
                                },
                                ...rest,
                            }
                        }}
                    >
                        Open in Editor
                    </CustomThemeButton>
                </div>
            )}
            {error && !loading && (
                <SectionMessage
                    title={error}
                    appearance={
                        error.includes('account') ? 'information' : 'warning'
                    }
                    actions={[
                        <a
                            href="https://support.appsplus.co"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontWeight: 500 }}
                        >
                            Support
                        </a>,
                        <a
                            href="https://docs.appsplus.co"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontWeight: 500 }}
                        >
                            Docs
                        </a>,
                    ]}
                >
                    For fast assistance please reachout
                </SectionMessage>
            )}
            {!hasSource && !loading && (
                <div className="footer">
                    <p className="disclosure">
                        Private diagrams are fetched in compliance with Limited
                        Use requirement in{' '}
                        <a
                            href="https://developers.google.com/terms/api-services-user-data-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Google API Services User Data Policy
                        </a>
                        .
                    </p>
                    <div className="terms">
                        <a
                            href="https://appsplus.co"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Apps+
                        </a>
                        |
                        <a
                            href="https://appsplus.co/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy
                        </a>
                        |
                        <a
                            href="https://appsplus.co/eula"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms
                        </a>
                        |
                        <a
                            href="https://github.com/search?q=repo%3Ajgraph%2Fdrawio+path%3A**%2Flicense&type=code"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Licenses
                        </a>
                    </div>
                </div>
            )}
        </div>
    )
}

export default App
