import pako from 'pako'

export const decodeXML = (src) => {
    let data

    // 1. uri
    try {
        data = decodeURIComponent(src)
    } catch (e) {}

    // 2. xml
    try {
        var node = parseXml(data).documentElement

        if (node != null && node.nodeName === 'mxfile') {
            var diagrams = node.getElementsByTagName('diagram')

            if (diagrams.length > 0) {
                data = getTextContent(diagrams[0])
            }
        }
    } catch (e) {}

    // 3. base64
    try {
        data = atob(data)
    } catch (e) {}

    // 4. inflate
    try {
        data = pako.inflateRaw(
            Uint8Array.from(data, (c) => c.charCodeAt(0)),
            { to: 'string' }
        )
    } catch (e) {}

    // 5. uri
    try {
        data = decodeURIComponent(data)
    } catch (e) {}

    // 6. return

    return data

    // functions

    function parseXml(xml) {
        var parser = new DOMParser()

        return parser.parseFromString(xml, 'text/xml')
    }

    function getTextContent(node) {
        return node != null
            ? node[node.textContent === undefined ? 'text' : 'textContent']
            : ''
    }
}
